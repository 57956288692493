// We can rely on ccikewebsite.js to include this in the common webpack bundle - so don't load it twice.
// @use 'fonts'
@use 'standard-block'
@import 'colours'
@import '~include-media/dist/_include-media.scss'
@import 'shadows'

.card-deck
    .card
        flex-grow: 1


.standard-cards
    color: white
    background: $gradient-background
    font-size: 1.5rem
    padding-top: 4rem
    flex-grow: 1 // Otherwise there's a white space between cards and footer
    // position: relative
    // top: -75px

    .multi-check-box-detail
        background: black
        border: 1px solid $esa-teal

    .standard-block
        margin-top: 80px

        .introduction
            font-family: NotesESAbold, sans-serif
            font-weight: 800
            font-size: 2.2rem
            padding-top: 2rem
            line-height: normal

        .lead-bodytext
            font-family: NotesESAbold, sans-serif
            font-weight: 800
            font-size: 2.2rem
            padding-top: 2rem
            line-height: normal

        .projects-card-deck
            margin-top: 80px !important


    .card
        color: white
        background-color: $deepspace-blue
        border: none
        margin-bottom: 5rem
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.10)

        .card-img-top
            max-height: 195px
            object-fit: cover
            border-radius: 0

        .card-image-link
            box-shadow: none
            border-bottom: none

        .card-title
            font-family: NotesESAbold, sans-serif
            font-size: 28px
            font-weight: 700
            line-height: 1.5em
            margin: 0
            margin-bottom: 1rem

        .card-date
            font-family: NotesESAregular, sans-serif
            font-weight: 100
            margin: 0
            font-size: 2rem
        .card-body
            font-size: 18px
            line-height: 27px
            height: 350px
            padding-left: 3rem
            padding-right: 3rem
            margin-top: 24px
            .promoted-item-text
                background: $deepspace-blue
                margin-left: -30px
                padding: 24px 24px 3rem 24px
            p
                font-family: NotesESAregular, sans-serif
            .card-btn
                // color: #ffffff !important
                text-align: left
                color: $esa-lightgrey !important
                text-transform: none
                background-color: transparent
                border: none
                width: max-content
                border-radius: 2px
                letter-spacing: 0.25rem
                transition: none
                font-size: 2rem
                font-family: NotesESAbold, sans-serif
                margin-bottom: 1rem
                margin-left: 0
                margin-right: 0
                padding-left: 0
                padding-right: 0

                &:hover
                    color: $esa-teal !important
                    background-color: transparent !important
            a
                border-bottom: none !important
                box-shadow: none !important
            a:hover
                text-decoration: none !important
                background-color: transparent !important
                color: $esa-teal !important
                transition: background 0.1s cubic-bezier(0, 174, 157,1) !important

#latest-news-events
    background: transparent
    margin-top: 5rem
    top: initial
    .row
        margin-top: 5rem
    .card
        background-color: $deepspace-blue
        .card-img-top
            max-height: 195px
            object-fit: cover

        a:hover
            text-decoration: none
            background-color: transparent
            border-bottom: 1px solid $esa-teal
            box-shadow: inset 0 -1px 0 $esa-teal
            color: $esa-teal
            transition: background 0.1s cubic-bezier(0, 174, 157,1)

#news-events-carousel
    background: transparent
    top: initial
    .row
        margin-top: 0
    .card
        background-color: $deepspace-blue
        .card-img-top
            max-height: 195px
            object-fit: cover
    .carousel-indicators
        margin: 2rem 0 0 0
        position: initial
        li
            width: calc(100% / 3)
            list-style: none

#allitems-btn
    .btn
        background-color: rgba(255, 255, 255, 0.85)
        color: $dark-green !important
        font-family: NotesESAbold, sans-serif
        letter-spacing: normal
        font-size: 2rem
        text-transform: none
        margin-top: 5rem
        padding: 16px 42px
        border-radius: 4px
        gap: 10px
        border-bottom: none !important
        box-shadow: none !important

        &:hover
            transform: none !important
            color: $esa-teal !important
            background-color: #ffffff !important

        &.active
            color: #fff
            background-color: $deep-space1

            &:hover
                transform: none
                cursor: default


        &.more
            border-style: dashed
            padding: 6px 10px
