@use 'fonts'
@use 'cards-carousels'
@import '~include-media/dist/_include-media.scss'
@import 'colours'
@import 'shadows'


// project_intro

// This is the 'Contact and support' block in project index pages.
// Note the styles at the bottom of base.sass are also relevant.
// TODO: teal-section, background-section and clear-section have a lot in common. Should there be a <something>-section class added to all these blocks?
#main-project-intro
    flex-grow: 1
    background: $gradient-background
    padding-bottom: 180px

    .project-intro-text
        margin-bottom: 5rem

    .btn
        text-align: left
        color: $esa-lightgrey !important
        text-transform: none
        background-color: transparent
        border: none
        width: max-content
        border-radius: 2px
        letter-spacing: 0.25rem
        transition: none
        font-size: 2rem
        font-family: NotesESAbold, sans-serif
        margin-bottom: 1rem
        margin-left: 0
        margin-right: 0
        padding-left: 0
        padding-right: 0

        &:hover
            color: $esa-teal !important
            background-color: transparent !important
    .card
        background: transparent  
        margin: 0
        padding: 0 

    #navbarProjectInner
        margin-top: 3rem
        margin-bottom: 7rem

        a
            font-size: 20px
            border-bottom: 0
            box-shadow: none
        
        .nav
            border-bottom: none

        a:hover,a:focus
            outline: none
            text-decoration: none
               
        .nav-tabs .nav-link,
        .nav-tabs .nav-item.show .nav-link
            color: $lightgrey
            border: none
            background-color: transparent
        .nav-tabs .nav-link.active
            color: $esa-teal
            border: none
            background-color: transparent
            border-bottom: solid 5px $esa-teal

        .nav-tabs .nav-link:hover
            color: $esa-teal55
            transform: none

#nav-tab-project
    margin-bottom: 10rem
    border-bottom: none

.block-docs_bundle
    margin-bottom: 5rem
    padding: 0

.teal-section
    color: $deepspace-blue
    background-color: $esa-teal55

#multi-news-cards
    h2
        color: $deepspace-blue

    p
        line-height: 1.1

    .controls
        background-color: #fff

    .carousel-inner
        margin-left: 0
        margin-right: 0
        padding-left: 0
        padding-right: 0

    .carousel-item::before
        background-image: none !important

#data-content

    .data-intro
        margin-bottom: 1rem
        font-size: 1.5rem
        font-weight: 800
        // text-transform: capitalize

    .data-left
        ul
            list-style-type: none
            padding-left: 0
            // text-transform: capitalize

        .btn
            margin: 0
            padding-left: 1rem
            padding-right: 1rem
            height: 3rem
            font-size: 1.5rem
            font-weight: 800
            background-color: $deepspace-blue
            color: #fff
            text-transform: none
            // text-transform: capitalize

    #data-right
        margin-right: 0
        padding-right: 0
        text-align: center

        .row
            margin-right: 1rem
            padding-right: 1rem
            
        .btn
            margin: 2rem 4rem 3rem 4rem
            height: auto
            width: auto
            text-align: left
            font-size: 2.5rem
            font-weight: 800
            background-color: $excite-red
            color: #fff
            text-transform: uppercase

            span
                font-size: 1.5rem
                text-transform: none

        img
            max-height: 300px
            margin: 0 auto

#key-docs
    
    p
        font-size: 1.5rem
        line-height: 1.5
        padding-bottom: 1.5rem
        color: $deepspace-blue

    // Post-dedup, included here are the differences from the controls in #multi-news-cards.
    // However, these styles appear to be unused.
    // TODO: Delete these if they're never going to be needed.
    .controls
        background-color: transparent

    .card
        background-color: $deepspace-blue !important
        color: #fff

        .card-body
            border-top: 3px solid $esa-teal

        &:hover
            background-color: $dark-yellow !important

#publications-content
    p
        margin-bottom: 2rem
        line-height: 1.1

    .publications-intro
        margin-bottom: 1rem
        font-size: 1.25rem
        // text-transform: capitalize

    .publications-left

        h2
            color: #ffffff
        ul
            list-style-type: none
            padding-left: 0
            // text-transform: capitalize

            li
                h3
                    font-size: 1.75rem
                    margin-top: 2rem
                pre
                    color: #fff
                    display: inline
                    margin-right: 2rem

                i
                    float: right
                    display: inline
                
                a
                    color: #fff
                    text-decoration: none

                a:visited
                    color: #fff
                    text-decoration: none

                a:active
                    color: #fff
                    text-decoration: none

                a:hover
                    color: $dark-yellow
                    text-decoration: none

        .btn
            margin: 0
            padding-left: 1rem
            padding-right: 1rem
            height: 3rem
            font-size: 1.5rem
            font-weight: 800
            background-color: $deepspace-blue
            color: #fff
            text-transform: none
            // text-transform: capitalize

    #publications-right
        margin-right: 0
        padding-right: 0
        text-align: center

        .row
            margin-right: 1rem
            padding-right: 1rem
            
        .btn
            margin-bottom: 1rem
            margin-top: 2rem
            padding-left: 4rem
            padding-right: 4rem
            height: 5rem
            font-size: 1.5rem
            font-weight: 800
            background-color: $deepspace-blue
            color: #fff

            span
                font-size: 1rem
                text-transform: none

        img
            max-height: 300px
            margin: 0 auto

#contact-content
    color: #000

    em
        font-style: normal
        font-weight: bold
        padding-right: 0.5rem

    ul
        padding: 0
        margin: 0
    li
        list-style-type: none
        padding-right: 5rem

    .contact-columns
        @media screen and (min-width: 992px)
            column-count: 2



#project-sponsors
    // We rely on the margin on li to provide most of the margin above and below the block.
    // It's in li so that we also get reasonable margin between rows when the list of sponsors wraps.
    margin: 4rem
    padding-top: 4rem
    color: $deepspace-blue
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center

    li
        margin: 1rem

    img
        height: 50px

// We want the project sponsors and below to remain stuck to the top of the footer, so the page grows white space
// above them, not between them and the footer.
#project-sponsors
    margin-top: auto

.project-page
    #bottompage
        margin-top: initial


// AddThis buttons
.addthis_inline_share_toolbox
  margin-top: 3rem

.figure-caption
    
    color: $esa-lightgrey !important

    a
        color: $esa-lightgrey !important
