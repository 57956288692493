// These styles are for the carousels which show groups of cards. This is currently used:
//  - At the bottom of the home page
//  - In the 'Project latest news & events' block in project home pages
//  - In the project 'key docs' block (but there's no carousel there at the moment, so it's unused)
//  - In the project news and events pages
@import 'colours'

#events-cards,
#multi-news-cards,
#key-docs,
#homepage-news-content
    .carousel
        position: relative
        padding: 0
        margin: 0

    .control-button
        font-size: 5rem
        display: flex
        align-items: center
        vertical-align: middle

    .control-left
        margin-right: 0
        padding-right: 0
        position: absolute
        left: -30px
        top: 10px

    .control-right
        margin-left: 0
        padding-left: 0
        position: absolute
        right: -5px
        top: 10px

    .controls
        margin: 0
        margin-top: 10%
        padding: 0
        height: 100%
        width: 1rem
        z-index: 10
        text-align: center
        font-size: 3rem
        display: block

        .btn-floating
            border-bottom: 0
            box-shadow: none

        .btn-floating:hover
            background-color: transparent
            border-color: transparent

    .card
        background-color: $lightgrey !important
        border: none
        font-size: 0.9rem
        color: $deepspace-blue

        // .card-img-top
        //     max-height: 150px

        .card-title
            font-size: 1.5rem
            font-weight: 800
            // text-transform: capitalize



#events-cards
    margin-bottom: 2rem
    h1
        padding: 1.5rem
        font-size: 4rem
        font-weight: 800
        color: $dark-blue

    h4
        margin: 1rem
        color: $deepspace-blue

    p
        margin: 1rem

    // FIXME:
    // .carousel-item
    //     border: 2px solid #fff
    //     padding: 1rem 0 1rem 0

    .card
        .card-body
            padding: 0.5rem
            padding-top: 1.5rem
            padding-bottom: 1rem
            min-height: 20rem

        .card-text
            font-size: 1.25rem
            font-weight: normal

            p
                font-size: 1.25rem


            p:first-of-type
                font-size: 1.25rem
                font-weight: normal
                line-height: 1rem

        .btn
            font-size: 1.2rem
            font-weight: 800
            background-color: $deepspace-blue
            color: #fff
            text-transform: none
            // text-transform: capitalize
            width: 75%


        &:hover
            background-color: $esa-teal !important


#multi-news-cards
    .carousel-item
        border: 2px solid #fff
        padding: 1rem 0 1rem 0

    .slide
        margin-left: -15px
        margin-right: -15px

    .card
        // GTS Remove to keep consistent with 'promoted-section'
        // .card-body
        //     padding: 0.5rem
        //     padding-top: 1.5rem
        //     padding-bottom: 1rem
        //     min-height: 5rem

        a
            text-decoration: none
        .card-body
            background-color: rgba(255, 255, 255, 1)

            p
                font-size: 1.4rem // This is the size of the text in the card.
            &:hover
                background-color: $esa-teal !important

    // .card-img
    //     height: 300px

    .card-img-overlay
        background-color: rgba(#000, 0.4)

        &:hover
            background-color: $esa-teal !important

// TODO: This isn't used? No carousel in key-docs section.
#key-docs
    .carousel-item
        padding: 1rem 0 1rem 0

    .slide
        margin-left: -15px
        margin-right: -15px

#homepage-news-content
    .control-right
        // There is more space available for this carousel so the arrows don't need to be so close.
        right: -20px
